import React, { useLayoutEffect, useState } from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { cn } from "../lib/helpers";
import { isSafari } from 'react-device-detect';
import { Helmet } from 'react-helmet';

import Hero from "../components/home-hero";
import ContentBlock from "../components/home-content-block";
import FlexibleContent from "../components/flexible-content";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      seo {
        title
      }
    }
    home: sanityHome {
      hero {
        title
        subtitle
        _rawContent
        button {
          text
          url
          secondaryButton
          blank
          document {
            asset {
              url
              originalFilename
            }
          }
        }
        image {
          ...SanityImage
          alt
        }
        videoButton {
          text
          url
        }
      }
      contentBlock {
        _rawTitle
        _rawSubtitle
        _rawContent
        button {
          text
          url
          secondaryButton
          blank
        }
        buttonExtendedReality {
          text
          url
          secondaryButton
          blank
        }
      }
      flexibleContent {
        ... on SanityFcPartners {
          _key
          _type
          title
          partners {
            _key
            partner {
              name
              slug {
                current
              }
              link
              logo {
                asset {
                  extension
                  url
                  _id
                }
                alt
              }
            }
          }
        }
        ... on SanityFcMultiColumnContent {
          _key
          _type
          title
          content {
            _key
            icon {
              asset {
                _id
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
          alternativeLayout
        }
        ... on SanityFcContentWithImage {
          _key
          _type
          title
          _rawSubtitle
          _rawContent
          button {
            text
            url
            secondaryButton
            blank
          }
          image {
            ...SanityImage
            alt
          }
        }
        ... on SanityFcClients {
          _key
          _type
          title
          clients {
            _key
            client {
              name
              logo {
                asset {
                  extension
                  url
                  _id
                }
                alt
              }
              link
            }
          }
        }
      }
      callToAction {
        title
        _rawDescription
        button {
          blank
          secondaryButton
          text
          url
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  const [init, setInit] = useState(false);

  function handleSetInit() {
    setInit(true);
  }

  useLayoutEffect(() => {
    handleSetInit();
  });

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;

  const title = home.seo?.title || site.seo?.title || site.title || "";
  const description = home.seo?.description;
  const image = home.seo?.thumbnail;
  const autoSiteTitle = !home.seo?.title;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={cn("page-has-hero", init && !isSafari && "color-palette")} lang="en" />
      </Helmet>
      
      {home.hero && (<Hero {...home.hero} />)}
      {home.contentBlock && (<ContentBlock {...home.contentBlock} />)}
      {home.flexibleContent && (<FlexibleContent items={home.flexibleContent} />)}
      {home.callToAction && (<CallToAction {...home.callToAction} />)}
    </Layout>
  );
};

export default IndexPage;
